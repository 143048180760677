
import CloudFun, { Condition, defineComponent, Operator, ref } from '@cloudfun/core'
import Grid, { GridOptions } from '@/cloudfun/components/Grid.vue'
import { VxeFormProps } from 'vxe-table'

export default defineComponent({
  components: {
    Grid
  },
  setup () {
    const model = CloudFun.current?.model
    const grid = ref<any>({})
    var printColumns = [
      { field: 'Name' },
      { field: 'Description' }
    ]

    const gridOptions: GridOptions = {
      stripe: false,
      title: '活動機關管理',
      toolbarConfig: {
        custom: true,
        refresh: true
      },
      multiselect: false,
      printConfig: { sheetName: '活動機關管理清單', columns: printColumns, modes: ['current', 'selected', 'all'] },
      exportConfig: { filename: '活動機關管理清單', type: 'csv', types: ['html', 'csv'], mode: 'all', modes: ['current', 'selected', 'all'], columns: printColumns },
      columns: [{
        field: 'Name',
        title: '名稱',
        showHeaderOverflow: true,
        showOverflow: true,
        sortable: true,
        treeNode: false,
      },
      { field: 'Published', title: '已發布', showHeaderOverflow: true, showOverflow: true, sortable: true, align: 'center', formatter: ({ cellValue }) => cellValue ? '是' : '否' },
      ],
      promises: {
        query: model ? (params) => {
          if (params?.sortings?.length === 0) {
            params.sortings.push({ column: 'CreatedTime', order: 1 });
          }
          return model.dispatch('unit/query', params)
        } : undefined,
        queryAll: model ? () => model.dispatch('unit/query') : undefined,
        save: model ? (params) => model.dispatch('unit/save', params) : undefined
      },
      modalConfig: { width: "50%", showFooter: true },
      // treeConfig: { children: 'Children' }
    }

    const formOptions: VxeFormProps = {
      titleWidth: 120,
      titleAlign: 'right',
      items: [
        {
          field: 'Name',
          title: '名稱',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
        },
        {
          field: 'Published',
          title: '已發布',
          span: 12,
          // titleWidth: 80,
          itemRender: { name: '$select', options: [{ value: false, label: '否' }, { value: true, label: '是' }] }
        },
      ],
      rules: {
        Name: [{ type: 'string', required: true }],
        Type: [{ required: true }],
      }
    }

    const initData = (row: any, callback: any) => {
      row.Published = false;
      row.Ordinal = 0;
      callback();
    }

    return {
      grid,
      gridOptions,
      formOptions,
      model,
      initData
    }
  },
  methods: {
    hideBatchDropDown () {
      cash('#batch-dropdown').dropdown('hide')
    }
  }
})
